var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(!_vm.vrm)?_c('div',{staticClass:"hero is-fullheight-with-navbar"},[_c('div',{staticClass:"hero-body"},[_c('div',{staticClass:"container"},[_c('Spinner')],1)])]):_c('div',[_c('section',{staticClass:"section"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column is-4-desktop is-6"},[_c('VehicleCarousel',_vm._b({on:{"check":_vm.precheck}},'VehicleCarousel',{
                vrm: _vm.vrm,
                cid: _vm.cid,
                photos: _vm.photos,
                hasAutoCheck: _vm.hasAutoCheck,
                hasMotorCheck: _vm.hasMotorCheck,
                precheck: _vm.$wait.is('precheck'),
                showCheck: !_vm.isIntegration && (_vm.hasAutoCheck || _vm.hasMotorCheck)
              },false)),_c('CompanionValuationActions')],1),_c('div',{staticClass:"column"},[_c('CompanionOverview')],1)])])]),_c('section',{staticClass:"section"},[_c('div',{staticClass:"container"},[_c('CompanionContent')],1)])])])
}
var staticRenderFns = []

export { render, staticRenderFns }